import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useTranslate,
} from "react-admin";
import { AccountType } from "../react-admin-loopback/api/shared-constants";
import { ViewImage } from "./view-image";
const CmrFilter = (props: any) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        label={translate("commons.driver")}
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        filter={{ type: AccountType.CONDUCTEUR }}
        alwaysOn
      >
        <SelectInput optionText="nom" />
      </ReferenceInput>
    </Filter>
  );
};

export const PleinList = (props) => {
  const translate = useTranslate();
  return (
    <List {...props} filters={<CmrFilter />}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          source="accountId"
          reference="Accounts"
          label={translate("commons.driver")}
        >
          <TextField source="nom" />
        </ReferenceField>
        <TextField
          source="autreStation"
          label={translate("commons.autreStation")}
        />
        <TextField source="type" />
        <TextField source="kilometrageauto" />
        <TextField source="litrageauto" />
        <DateField source="dateheureauto" showTime />
        {/* <ImagesShowInTable source="image" headerClassName="w-30" /> */}
        {/* <ViewImage
          label={translate("commons.image")}
          source="image"
          containerId="cMRId"
        /> */}
        <ReferenceField
          source="cMRId"
          reference="CMRs"
          label={translate("commons.image")}
          link={false}
        >
          <ViewImage source="image" containerId="cmrs" />
          {/* <TextField source="id" /> */}
        </ReferenceField>

        <TextField source="kilometrage" />
        <TextField source="litrage" />
        <TextField source="station" />
        <BooleanField source="complet" />

        <DateField source="createdAt" label={translate("commons.createdAt")} />
        <DateField source="updatedAt" label={translate("commons.updatedAt")} />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
